@import "../../../styles/variables";

.component-data-placehodler {
  background-color: white;
  border-radius: 16px;

  .error-message {
    display: inline-block;
    text-align: center;
    color: $red;
    text-transform: uppercase;
    width: 100%;
    padding-top: 8px;
  }

  .empty-img {
    display: block;
    margin: auto;
    padding-top: 138px;
    padding-bottom: 32px;
  }
  .title {
    color: $midnight100;
    font-weight: 800;
    font-size: 21px;
    line-height: 28px;
    text-align: center;
  }
  .message {
    width: 292px;
    margin: 13px auto 13px auto;

    color: $midnight100;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-feature-settings:
      "salt" on,
      "ss01" on;

    @media (max-width: $mobile-max-width) {
      white-space: break-spaces;
      width: auto;
    }
  }

  .action {
    text-align: center;
    padding-bottom: 100px;
  }
}
