@import "../../styles/variables";

button.btn {
  margin: 16px 0;
  border-style: none;
  border-radius: 4px;
  box-shadow: none;

  justify-content: center;
  align-items: center;
  padding: 8px 24px;

  font-weight: 800;
  font-size: 13px;
  line-height: 24px;

  &:hover {
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
  }

  &.mini {
    margin: 3px 0;
    padding: 0 6px;
  }

  &.fill {
    color: $midnight4;
    background-color: $gulf;
    &:disabled {
      background-color: $dusk40;
    }
  }
  &.basic {
    color: $gulf;
    background-color: transparent;
    &:disabled {
      color: $dusk40;
    }
  }
  &.alt {
    color: $gulf;
    background-color: $midnight4;
    &:disabled {
      color: $dusk40;
    }
  }
  &.outline {
    border: 1px solid $gulf;
    background-color: transparent;
    &:disabled {
      color: $dusk40;
      border-color: $dusk40;
    }
  }
  &.warning {
    color: $red;
    background-color: $background-red;
    border: 1px solid $red;
    &:disabled {
      color: $dusk40;
      background-color: $dusk-lightest;
      border: 1px solid $dusk40;
    }
  }

  &.btn-add {
    .btn-add-icon {
      svg {
        position: relative;
        top: 6px;
        padding-left: 6px;
        width: 22px;
        height: 22px;
      }

      .fill {
        path {
          fill: $midnight4;
        }
      }
      .basic,
      .alt,
      .outline {
        path {
          fill: $gulf;
        }
      }
      .warning {
        path {
          fill: $red;
        }
      }
    }
  }
}
