@import "../../../../../styles/variables";

.InputExternalIdStep {
  :global .header {
    text-align: left;
    font-weight: 800;
    .title {
      font-size: 16px;
      line-height: 24px;
    }
    .message {
      margin-top: 16px;
      color: $dusk40;
      font-size: 10px;
      line-height: 12px;
    }
  }

  :global .input-form {
    text-align: center;

    .content {
      input {
        width: calc(100% - 32px);
      }
    }

    .validation-message {
      text-align: left;
      font-weight: 800;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      &.valid {
        color: $green;
      }
      &.invalid {
        color: $red;
      }
    }
  }
  :global .footer {
    width: 100%;

    button {
      width: 100%;
    }
  }
}
