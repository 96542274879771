@import "../../../styles/variables";

.EditDeviceDialog {
  :global .edit-form {
    font-size: 18px;
    font-weight: 800px;
    color: $midnight100;

    .row {
      display: block;

      .active {
        label {
          margin: 8px;
        }
      }

      .col {
        display: inline-table;
        padding: 8px 16px 8px 0;
      }
      .external-id {
        input {
          margin-bottom: 0;
        }
      }
      .vendor {
        select {
          margin-bottom: 0;
        }
      }
    }

    .validation-message {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 10px;
      line-height: 12px;

      &.invalid {
        color: $red;
      }
    }

    .actions {
      div {
        display: inline-block;
        width: 50%;
      }
      .submit {
        text-align: right;
      }
      .spinner {
        position: relative;
        float: right;
        width: auto;
        top: 18px;
      }
    }
  }

  .ErrorMessage {
    color: $red;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    font-weight: 800;
  }
}
