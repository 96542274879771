@import "../../styles/variables";

.component-select {
  select {
    margin: 11px 0;
    padding: 16px;
    border: 1px solid $midnight10;
  }
}

.component-select-list {
  .select-form {
    text-align: left;

    .select-list-header {
      text-align: left;
      font-weight: 800;
      .title {
        font-size: 16px;
        line-height: 24px;
      }
      .message {
        &.spaced {
          margin-top: 8px;
        }
        color: $dusk40;
        font-size: 10px;
        line-height: 12px;
      }
    }

    .filter {
      input {
        width: 100%;
        padding: 8px;
      }
    }

    .content {
      max-height: 300px;
      overflow-y: scroll;
      width: calc(100% + 16px); // add room for scrollbar

      .select-option {
        position: relative;
        width: calc(100% - 24px); // adjust for scrollbar

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        min-height: 56px;
        border-bottom: 1px solid $background-blue;
        color: $gulf;
        &:last-child {
          border-bottom: none;
        }

        .display-text {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .select-option-icon {
          padding-right: 6px;

          svg {
            position: relative;
            top: 2px;

            width: 22px;
            height: 22px;

            path {
              fill: $gulf;
            }
          }
        }

        .label {
          font-weight: 500;
          font-size: 14px;
          cursor: pointer;
        }
        .radio-input {
          float: right;
          margin-top: 12px;
          input {
            accent-color: $gulf;
          }
        }
      }
    }
  }
}
