@import "../../../styles/variables";

.component-device-compatibility {
  margin: 16px 0;

  .compatibility-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .col {
      flex: 1 1 auto;
      flex-basis: 33%;
      padding: 0 6px;
    }

    padding: 16px;
    margin-bottom: 9px;

    background: $midnight4;
    border-radius: 4px;

    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;

    letter-spacing: 2px;
    text-transform: uppercase;
    font-feature-settings:
      "ss06" on,
      "case" on;

    color: $midnight100;
  }

  .compatibility-error {
    padding: 16px;
    color: $red;
    background-color: white;
  }

  .component-compatibility-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .col {
      flex: 1 0 auto;
      flex-basis: 25%;
    }

    padding: 0 16px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    font-feature-settings:
      "salt" on,
      "ss01" on;

    background-color: white;

    &:not(:last-child) {
      border-bottom: 2px solid $background-blue;
    }
  }
}
