@import "../../../../../styles/variables";

.component-device-item-detail {
  min-width: 420px;

  .device-detail-header {
    position: relative;
    margin-bottom: 8px;

    .icon {
      position: relative;
      top: 5px;
      margin-right: 8px;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: $dusk;
        }
      }
    }

    .title {
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      color: $dusk;
    }
  }

  table.device-detail-table {
    width: 100%;

    color: $dusk100;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    td {
      padding: 5px 0;
    }

    .label {
      color: $dusk40;
      font-weight: 800;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      padding-bottom: 2px;
    }
    .macaddress {
      color: $midnight70;
      font-weight: 500;
    }
    .unweighted {
      font-weight: 400;
    }

    .float-right {
      text-align: right;
    }
  }

  .device-detail-container {
    width: 100%;
    color: $dusk100;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    .row {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;

      .col {
        padding: 5px 0;

        .label {
          color: $dusk40;
          font-weight: 800;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          padding-bottom: 2px;
        }

        .macaddress {
          color: $midnight70;
          font-weight: 500;
        }
        .unweighted {
          font-weight: 400;
        }

        .float-right {
          text-align: right;
        }
      }
    }
    .note-text {
      color: $dusk40;
      font-weight: 500;
      font-size: 12px;
    }
  }

  .detail-child-wrapper {
    padding: 16px;
    margin-top: 26px;
    background: $midnight4;
    border-radius: 4px;
  }

  @media (max-width: $mobile-max-width) {
    min-width: 0;
    width: 100%;

    .device-detail-container {
      .row {
        .col {
          .float-right {
            text-align: left;
          }
        }
      }
    }
  }
}
