@import "../../../../styles/variables";

.component-device-monitoring-alert {
  padding: 16px 0;
  overflow: auto;

  &:not(:last-child) {
    border-bottom: 1px solid $midnight10;
  }

  .alert-details {
    display: inline-flex;
    flex-direction: column;
    max-width: 60%;
    overflow-wrap: break-word;

    .alert-info {
      .badge {
        position: relative;
        display: inline-block;
        border-radius: 100px;
        padding: 2px 8px;
        margin-right: 8px;

        &.SensorNoiseAlert,
        &.SensorTemperatureAlert {
          background-color: $laguna30;
        }
        &.RouterConnectedAlert {
          background-color: $gulf10;
        }

        svg {
          position: relative;
          top: 2px;

          width: 21px;
          height: 21px;

          path {
            fill: $dusk;
          }
        }
      }
      .timestamp {
        position: relative;
        top: -4px;

        color: $dusk40;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
      }
    }

    .macAddress {
      color: $midnight70;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 2px 0;
    }

    .device-detail {
      color: $midnight100;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .subscriber-details {
    display: inline-flex;
    flex-direction: column;
    float: right;
    align-items: flex-end;

    max-width: 40%;
    overflow-wrap: break-word;

    .subscriber-badge {
      max-width: 100%;
      margin: 2px 0;
      padding: 2px 6px;
      border-radius: 6px;
      width: max-content;

      background-color: $midnight4;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      text-align: left;

      span:not(:first-child) {
        margin-left: 6px;
      }

      .icon {
        position: relative;
        top: 3px;

        &.sms svg {
          width: 15px;
          height: 15px;
        }
        &.mail svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
