$application-max-width: 1125px;

// This variable is also defined in src/core/constants.tsx
$mobile-max-width: 800px;

$background-blue: #e5ebed;
$background-green: #f0f5ec;
$background-red: #faeaed;

$disabled-grey: #a0a0a0;

$red: #d62e4f;
$green: #6ba342;

$dusk: #27221d;
$dusk-lightest: #c7d0d6;
$dusk40: #707579;
$dusk100: #2c3439;

$gulf: #007f90;
$gulf10: #e6f2f4;
$lake: #0078ab;

$midnight4: #f5f7f8;
$midnight10: #e5ebed;
$midnight30: #b2c2c8;
$midnight60: #668592;
$midnight70: #4d7080;
$midnight80: #335c6d;
$midnight100: #003349;

$laguna30: #fad7c1;

$overlay: rgba(0, 0, 0, 0.15);
