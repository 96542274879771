@import "../../styles/variables";

.component-spinner-overlay {
  position: absolute;
  border-radius: inherit;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $overlay;
  z-index: 1000;

  .loading-spinner-wheel {
    position: relative;
    top: calc(50% - 30px);
    height: 60px;
    width: 60px;
    margin: auto;
    -webkit-animation: component-spinner-overaly-rotation 1s infinite linear;
    -moz-animation: component-spinner-overaly-rotation 1s infinite linear;
    -o-animation: component-spinner-overaly-rotation 1s infinite linear;
    animation: component-spinner-overaly-rotation 1s infinite linear;
    border: 9px solid transparent;
    border-left: 9px solid $gulf;
    border-top: 9px solid $gulf;
    border-radius: 100%;
    z-index: 1001;
  }
}

@-webkit-keyframes component-spinner-overaly-rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes component-spinner-overaly-rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes component-spinner-overaly-rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes component-spinner-overaly-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
