@import "../../../styles/variables";

.component-update-alert-subscriber {
  .component-title {
    color: $dusk100;
    font-weight: 800;
    font-size: 21px;
    line-height: 48px;
  }

  .name {
    color: $midnight100;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 6px;
  }
  .form-option {
    color: $dusk40;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    .label {
      padding: 8px;
    }
  }
  .spinner {
    position: relative;
    top: 14px;
  }

  .subscribe-channel {
    .label-title {
      color: $midnight100;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      margin-top: 12px;
      margin-bottom: 6px;
    }
    .badge {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 10px;
      margin: 0 6px 3px 0;
      gap: 8px;
      border-radius: 100px;
      width: max-content;
      cursor: pointer;

      font-weight: 800;
      font-size: 10px;
      line-height: 11px;
      color: $dusk;

      &.unchecked {
        color: $dusk40;
        border: 1px dashed $dusk40;

        .icon {
          svg {
            position: relative;
            width: 21px;
            height: 21px;
            top: 1px;

            path {
              fill: $dusk40;
            }
          }
        }
      }

      &.checked {
        border: 1px solid transparent;
        &.SensorNoiseAlert,
        &.SensorTemperatureAlert {
          background-color: $laguna30;
        }
        &.RouterConnectedAlert {
          background-color: $gulf10;
        }

        .icon {
          svg {
            position: relative;
            width: 21px;
            height: 21px;
            top: 1px;

            path {
              fill: $dusk;
            }
          }
        }
      }
    }
  }

  @media (min-width: $mobile-max-width) {
    min-width: 400px;
  }
}
