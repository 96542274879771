@import "../../styles/variables";

.DataResolverContainer {
  position: relative;
  margin: auto;
  max-width: $application-max-width;
}

.DataResolverHeader {
  position: relative;
  display: block;
  min-height: 67px;

  // make nav menu clickable with overlapping div '.DataResolverContent'
  z-index: 10;
  width: max-content;

  .title {
    display: inline-block;
    width: 35%;
    text-align: left;
    font-weight: 800;
    font-size: 24px;
    margin: 16px 0;
  }
}

// Offset so right side buttons are aligned vertically with navbar
.DataResolverContent {
  position: relative;
  top: -76px;
}

.error-message {
  color: $red;
}
