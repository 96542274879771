@import "../../../styles/variables";

.component-add-monitoring-device {
  min-width: 380px;
  @media (max-width: $mobile-max-width) {
    min-width: unset;
  }

  .add-monitoring-device-header {
    padding-bottom: 12px;

    .title {
      color: $dusk;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
    }
    .device-icon {
      svg {
        position: relative;
        top: 5px;
        padding-right: 8px;

        width: 22px;
        height: 22px;

        path {
          fill: $dusk;
        }
      }
    }
  }

  .spinner {
    padding: 6px;
    position: relative;
    top: 14px;
  }
  .sensor-message {
    position: relative;
    top: 28px;

    color: $dusk40;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    &.error {
      color: $red;
    }
    &.spinner-form-inline {
      top: 41px;
    }
  }
}
