@import "../../styles/variables";

.error-badge {
  position: relative;
  display: inline-block;

  &.center {
    left: 50%;
    transform: translate(-50%, 0);
  }
  &.right {
    left: 100%;
    transform: translate(-100%, 0);
  }
  &.full-width {
    display: block;
  }

  color: $red;
  background-color: $background-red;
  text-align: center;
  padding: 8px;
  margin: 8px 0;
  border-radius: 8px;
  max-width: calc(100% - 16px);

  .error-message {
    .message {
      margin: 0 16px;
    }
    .detail-toggle {
      display: none;
      position: absolute;
      right: 8px;
    }
    &.dynamic {
      cursor: pointer;
      .detail-toggle {
        display: inline-block;
        &.closed {
          top: 5px;
          font-weight: bold;
        }
      }
    }
  }

  .error-detail {
    max-height: 0px;
    transition: max-height 0.25s ease-in-out;

    margin: 0 16px;
    color: $dusk40;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 11px;
    text-align: left;

    &.open {
      padding-top: 8px;
      max-height: 450px;
    }

    pre {
      overflow-x: scroll;
    }
  }
}
