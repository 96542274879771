@import "../../../styles/variables";

.component-remove-alert-subscriber {
  .component-title {
    color: $dusk100;
    font-weight: 800;
    font-size: 21px;
    line-height: 48px;
  }
  .message {
    padding-bottom: 16px;
  }
  .spinner {
    position: relative;
    top: 14px;
  }
  @media (min-width: $mobile-max-width) {
    min-width: 400px;
  }
}
