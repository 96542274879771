@import "../../../styles/variables";

.component-unauthorized-message {
  background-color: white;
  border-radius: 16px;
  margin-top: 76px;
  padding: 16px;

  .title {
    color: $midnight100;
    font-weight: 800;
    font-size: 21px;
    line-height: 28px;
    text-align: center;
  }
  .message {
    width: 440px;
    margin: 13px auto 13px auto;

    color: $midnight100;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-feature-settings:
      "salt" on,
      "ss01" on;

    @media (max-width: $mobile-max-width) {
      white-space: break-spaces;
      width: auto;
    }
  }
}
