@import "../../../../../styles/variables";

.component-edit-device {
  .edit-device-footer {
    width: 100%;

    .spinner {
      position: relative;
      top: 13px;
      float: right;
    }

    .error-message {
      color: $red;
      font-weight: 800;
      font-size: 13px;
      line-height: 24px;
    }
  }
}
