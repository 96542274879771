@import "../../../styles/variables";

.nav-bar {
  display: inline-block;
  background-color: transparent;
  vertical-align: middle;
  text-align: left;

  width: max-content;
  padding: 16px 0;

  .nav-items {
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    .nav-item {
      display: inline-block;

      color: $midnight70;
      text-align: center;

      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      border-bottom: 1px solid $midnight30;

      cursor: pointer;
      padding: 16px;

      &.active {
        font-weight: 800;
        color: $midnight100;
        border-bottom: 3px solid $midnight100;
        padding-bottom: 14px;
      }

      a {
        color: inherit;
      }
    }
  }

  .hamburger {
    display: none;
    margin: 0 12px;
    cursor: pointer;
    width: max-content;

    .bar {
      display: block;
      background-color: $dusk40;
      width: 25px;
      height: 3px;
      margin: 5px 0;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }

  @media (max-width: $mobile-max-width) {
    &.active {
      position: fixed;
      width: 100%;
      background-color: $background-blue;
      top: 0;
      left: 0;
      right: 0;
    }

    .hamburger {
      display: block;

      &.active {
        .bar:nth-child(1) {
          transform: translateY(8px) rotate(45deg);
        }
        .bar:nth-child(2) {
          opacity: 0;
        }
        .bar:nth-child(3) {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }

    .nav-items {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: $background-blue;

      left: -100%;
      &.active {
        left: 0;
      }

      .nav-item {
        display: block;

        &.active {
          font-weight: 800;
          color: $midnight100;
          border-bottom: 1px solid $midnight30;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
