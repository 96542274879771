@import "../../../styles/variables";

.component-add-lock-device {
  width: 330px;

  @media (max-width: $mobile-max-width) {
    width: 100%;
  }

  .step-scroll-container {
    position: relative;
    padding: 0 7px 0 0;
    margin: 0 -11px 0 0;

    max-height: calc(100vh - 200px);

    overflow-y: scroll;
    overflow-x: hidden;
  }

  .step-header {
    text-align: left;
    font-weight: 800;
    margin-bottom: 8px;

    .title {
      font-size: 16px;
      line-height: 24px;
    }
    .description {
      color: $dusk40;
      font-size: 10px;
      line-height: 12px;
      margin-top: 8px;
    }
  }

  .step-body {
    color: $dusk40;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;

    input {
      width: calc(100% - 32px);
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .select-form-footer {
    width: 100%;

    .back {
      display: inline-block;
      width: 50%;
      text-align: left;
    }
    .next {
      display: inline-block;
      width: 50%;
      text-align: right;
    }
    .full-width-next {
      display: block;
      width: 100%;
      text-align: center;

      .btn {
        width: 100%;
      }
    }
  }
}
