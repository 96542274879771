button {
  margin: 16px 0;
  border-style: none;
  border-radius: 4px;
  box-shadow: none;

  justify-content: center;
  align-items: center;
  padding: 8px 24px;

  font-weight: 800;
  font-size: 13px;
  line-height: 24px;

  color: $gulf;
  background-color: $midnight4;

  &:hover {
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
  }
  &:disabled {
    background-color: $disabled-grey;
  }

  &.btn-primary {
    color: $midnight4;
    background-color: $gulf;
  }
  &.btn-alt {
    border: 1px solid $gulf;
    background-color: transparent;
  }
  &.btn-alert {
    color: $red;
    background-color: $background-red;
    border: 1px solid $red;
  }
}
