@import "../../../../../styles/variables";

.component-toggle-device-alerts {
  .pause-detail {
    .label {
      color: $dusk40;
      font-weight: 800;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
    .date-time {
      color: $dusk100;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .resume-detail {
    color: $dusk100;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .toggle-device-alert-footer {
    width: 100%;

    .spinner {
      position: relative;
      top: 13px;
      float: right;
    }

    .error-message {
      color: $red;
      font-weight: 800;
      font-size: 13px;
      line-height: 24px;
    }
  }
}
