@import "../../../styles/variables";

.component-device-charts {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 12px;

    margin: 8px 24px;

    .icon {
      position: relative;
      top: 2px;
      width: 24px;
      height: 24px;
    }

    .title {
      color: $dusk;
      font-weight: 800;
      font-size: 21px;
      line-height: 28px;
      white-space: break-spaces;
    }

    .refresh-btn {
      position: relative;
      display: inline-block;
      color: $gulf;
      cursor: pointer;

      .icon-refresh {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        top: 5px;
        path {
          fill: $gulf;
        }
      }

      button {
        margin: 0;
        padding: 0;
        vertical-align: middle;
        background-color: transparent;
      }
    }

    .chart-filter {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      height: 45px;
    }
  }

  .chart-placeholder {
    margin: 16px 57px;
  }

  .bar-chart-wrapper {
    margin: 0 85px;
  }

  @media (max-width: $mobile-max-width) {
    .header {
      flex-wrap: wrap;
      justify-content: space-between;

      .title {
        width: min-content;
        font-size: 14px;
        line-height: 18px;
        flex: 1 1 auto;
        text-align: center;
      }
      .chart-filter {
        flex: 0 0 auto;
        justify-content: space-around;
        width: 100%;
      }
    }
    .bar-chart-wrapper {
      margin: 0 20px;
    }
  }
}
