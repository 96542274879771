@import "../../../../../../../styles/variables";

.register-turnkey-lock {
  .loading-spinner {
    position: relative;
    width: 100%;
    padding-top: 16px;

    svg {
      width: 100%;
      margin: auto;
    }
  }

  .error-alert {
    color: $red;
    background-color: $background-red;
    text-align: center;
    padding: 8px;
    margin: 8px 0;
    border-radius: 8px;
  }
}
