@import "../../../styles/variables";

.DialogContent {
  position: relative;
  background-color: inherit;

  :global .title {
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 32px;
  }
  :global .title-message {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 32px;
  }
  :global .action {
    div {
      display: inline-block;
      width: 50%;

      &.remove {
        text-align: right;
      }
    }
  }
}

.ErrorMessage {
  color: $red;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
}
