@import "../../styles/variables";

.component-input {
  display: inline-table;
  margin-right: 8px;

  .label {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    font-weight: 500;
    &.disabled {
      color: $dusk40;
    }

    &.compact {
      padding-bottom: 8px;
      font-weight: 800;
      font-size: 10px;
      line-height: 0;
      letter-spacing: 1.5px;
    }

    .help-text {
      position: relative;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  input {
    position: relative;
    padding: 8px 4px;
    margin: 4px 0 2px 0;
    border: 1px solid $midnight10;

    &.invalid {
      border: 1px solid $red;
    }

    // Get rid of up/down arrows
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    // Cover the input with the date picker button, but make it invisible
    &[type="date"]::-webkit-calendar-picker-indicator {
      position: absolute;
      background: transparent;
      color: transparent;
      cursor: pointer;
      pointer-events: auto;
      width: auto;
      height: auto;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 100;
    }
  }

  .spinner {
    position: relative;
    top: -6px;
  }

  .validation-error {
    height: 20px;
    color: $red;
    text-transform: lowercase;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
  }
}

.component-input-date {
  .input-wrapper {
    position: relative;
    padding: 1px 4px 8px 4px;
    margin: 4px 0 2px 0;
    border: 1px solid $midnight10;

    &.invalid {
      border: 1px solid $red;
    }

    input {
      position: initial;
      pointer-events: auto;
      border: none;
      padding: 0;
      margin: 0;
      z-index: 10;
      &.invalid {
        border: none;
      }
    }

    .calendar-icon {
      cursor: pointer;
      pointer-events: none;
      position: relative;
      width: 18px;
      height: 18px;
      top: 5px;
      padding-left: 4px;
      z-index: 1;

      path {
        fill: $midnight60;
      }

      &.disabled {
        path {
          fill: $dusk40;
        }
      }
    }
  }
}
