@import "../../../styles/variables";

.DeviceDetailsDialog {
  :global .details-dialog {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .details-dialog-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 800;
      color: $midnight100;
      padding-bottom: 12px;
    }

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .col {
        display: inline-table;
        padding: 3px 16px 3px 0;

        &.detail-key {
          text-transform: capitalize;
        }
        &.detail-value {
          color: $dusk40;
        }
      }
    }
  }
}
