@import "../../../../../styles/variables";

.component-unregister-device {
  .confirmation {
    color: $dusk;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .unregister-device-footer {
    width: 100%;

    .spinner {
      position: relative;
      top: 13px;
      float: right;
    }

    .error-message {
      color: $red;
      font-weight: 800;
      font-size: 13px;
      line-height: 24px;
    }
  }
}
