@import "../../../styles/variables";

.component-alert-subscribers {
  .subscriber-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  .footer {
    text-align: center;
  }
}
