@import "../../styles/variables";

.component-sound-monitoring-alerts {
  .card {
    background-color: white;
    margin: 72px 0 16px 0;
    padding: 8px 0;
    box-shadow: 1px 1px 20px rgba(112, 117, 121, 0.2);
    border-radius: 4px;

    .card-container {
      margin: 0 24px;

      .header {
        margin: 8px 0;
        height: 94px;

        .back-btn {
          padding: 4px 10px 4px 4px;
          border-radius: 100px;
          border: 1px solid $gulf;
          .icon {
            position: relative;
            top: 3px;
            left: 2px;
            margin-right: 4px;
            width: 18px;
            height: 18px;
            path {
              fill: $gulf;
            }
          }
        }

        .title {
          display: inline-block;
          padding-top: 32px;
          margin: 0 8px;
          color: $dusk;
          font-weight: 800;
          font-size: 21px;
          line-height: 28px;
        }

        .date-filters {
          float: right;
        }
      }

      .body {
        .no-data {
          margin: 24px;
        }
        .alert-row {
          margin: 0 8px;
          &:not(:last-child) {
            border-bottom: 1px solid $midnight10;
          }
        }
      }

      .footer {
        .spinner {
          padding: 8px;
        }
        .error-message {
          font-weight: 800;
          font-size: 13px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: $mobile-max-width) {
    .card {
      .card-container {
        .header {
          height: unset;

          .date-filters {
            float: none;
          }
        }
      }
    }
  }
}
