@import "../../styles/variables";

.component-toggle {
  &.component-yes-no-toggle {
    display: inline-flex;
    flex-direction: row;

    .value {
      padding: 6px 12px;
      color: $dusk40;
      border: 1px solid $dusk40;
      cursor: pointer;

      &.disabled {
        color: $disabled-grey;
        border: none;
        cursor: auto;
      }

      &:last-child {
        border-left: none;
      }
    }

    .value-no {
      @extend .value;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;

      &.selected {
        color: white;
        border-color: $red;
        background-color: $red;

        &.disabled {
          color: $dusk40;
          border-color: $background-red;
          background-color: $background-red;
        }
      }
    }
    .value-yes {
      @extend .value;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;

      &.selected {
        color: white;
        border-color: $green;
        background-color: $green;

        &.disabled {
          color: $dusk40;
          border-color: $background-green;
          background-color: $background-green;
        }
      }
    }
  }
}
