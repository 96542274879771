@import "./variables";

.ag-theme-alpine {
  .ag-overlay-loading-wrapper {
    background-color: transparent !important;
  }
  .ag-root-wrapper {
    border: none !important;
  }
  .ag-root {
    .ag-header-container {
      background-color: $midnight4;
    }
    .ag-header {
      border: none;

      .ag-header-cell {
        padding-left: 1px;
        padding-right: 0;

        .ag-cell-label-container {
          .ag-header-cell-label {
            color: $midnight100;
            text-align: center;
            letter-spacing: 2px;
            font-weight: bold;
            justify-content: center;

            .ag-header-cell-text {
              overflow: hidden;
              white-space: break-spaces;
              text-overflow: clip;
            }
          }

          .ag-icon {
            font-weight: bold;
            &.ag-icon-asc {
              transform: rotate(-90deg) !important;
              -webkit-transform: rotate(-90deg) !important;
              &::before {
                content: "\f12f"; // left chevron (rotated upwards)
              }
            }
            &.ag-icon-desc::before {
              content: "\f131"; // down chevron
            }
            &.ag-icon-none::before {
              content: "\f12f"; // right chevron
            }
          }
        }
      }
    }

    .ag-body-viewport {
      background-color: $background-blue;

      .ag-row {
        background-color: $background-blue;
        border: none;
        border-top: 1px solid $midnight4;
        &.ag-row-first {
          border-top: none;
        }

        .ag-cell {
          color: $midnight80;
          text-align: center;
          display: flex;
          justify-content: center; /* align horizontal */
          align-items: center;
          white-space: pre-wrap;
          line-height: inherit;

          &.ag-cell-focus:not(.ag-cell-range-selected) {
            border: none;
          }

          button {
            color: $dusk40;
          }
        }
      }
    }
  }

  @media (max-width: $mobile-max-width) {
    .ag-root {
      .ag-header {
        .ag-header-cell {
          .ag-cell-label-container {
            .ag-header-cell-label {
              letter-spacing: 0;

              .ag-header-cell-text {
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}
