@import "../../../styles/variables";

.component-monitoring-device-alerts {
  .footer {
    color: $lake;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    margin: 8px auto;
  }
}
