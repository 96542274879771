@import "../../../../../styles/variables";

.turnkey-setup {
  .code-block {
    padding: 8px;
    margin: 8px;
    width: fit-content;
    background-color: $midnight30;
    color: $dusk;
  }
  .component-yes-no-toggle {
    margin: 8px 0;
  }
  .done {
    background-color: $background-green;
    color: $green;
    padding: 16px;
    margin: 8px;
    border-radius: 8px;
  }
}
