@import "../../../../styles/variables";

.component-cell-renderer-menu {
  position: relative;
  overflow: visible;

  .ellipsis-wrapper {
    margin: 6px;
    padding: 0;
    border-radius: 100px;
    background-color: white;
    height: 20px;
    width: 20px;
    display: flex;
    flex-direction: row;

    .ellipsis-dot {
      border-radius: 100px;
      margin: 0 1px;
      background-color: $dusk40;
      width: 2px;
      height: 2px;
    }
    .close {
      display: none;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .menu-wrapper {
    display: none;
    position: absolute;
    right: 20px;
    top: -16px;
    background-color: white;
    text-align: left;
    margin: 0 12px;
    border-radius: 4px;

    .menu {
      padding: 6px;

      .menu-item {
        line-height: 26px;
        &:not(:last-child) {
          border-bottom: 1px solid $midnight10;
        }
      }
    }
  }

  &.ag-grid-open-menu {
    .ellipsis-wrapper {
      .ellipsis-dot {
        display: none;
      }
      .close {
        display: flex;
      }
    }
    .menu-wrapper {
      display: flex;
    }
  }
}
