@import "../../styles/variables";

.component-full-screen-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $overlay;

  .dialog-content {
    position: relative;
    padding-bottom: 16px;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    max-width: 1100px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 1px 1px 20px rgba(112, 117, 121, 0.2);
  }

  .dialog-content-container {
    position: relative;
    margin: 0 16px;
  }

  .dialog-header {
    padding-bottom: 16px;
    .title {
      color: $dusk100;
      font-weight: 800;
      font-size: 21px;
      line-height: 48px;
    }
    .action {
      line-height: 43px;
      float: right;
      .btn-close-dialog {
        margin: 0;
        padding: 0;
        border-radius: 100px;
        width: 30px;
        height: 30px;
        background-color: transparent;
      }
    }
  }
}

.component-anchor-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $overlay;
  z-index: 1000;

  .dialog-content-wrapper {
    position: fixed;
    background-color: transparent;
    padding-bottom: 16px;

    overflow: hidden;

    -webkit-transition:
      max-width 0.2s ease-in,
      max-height 0.2s ease-in;
    -moz-transition:
      max-width 0.2s ease-in,
      max-height 0.2s ease-in;
    -o-transition:
      max-width 0.2s ease-in,
      max-height 0.2s ease-in;
    transition:
      max-width 0.2s ease-in,
      max-height 0.2s ease-in;

    &.anchor-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.anchor-top {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &.anchor-top-right {
      top: 0;
      right: 0;
    }
    &.anchor-right {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &.anchor-bottom-right {
      bottom: 0;
      right: 0;
    }
    &.anchor-bottom {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &.anchor-bottom-left {
      bottom: 0;
      left: 0;
    }
    &.anchor-left {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &.anchor-top-left {
      top: 0;
      left: 0;
    }
    @media (max-width: $mobile-max-width) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: unset !important;
      margin-top: 0 !important;
      margin-left: 0 !important;
    }
  }

  .dialog-content-container {
    position: relative;
    transform: unset;

    @media (max-width: $mobile-max-width) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: unset;
      margin-top: 8px !important;
      margin-left: 8px !important;
      margin-right: 8px;
      margin-bottom: 8px;
    }

    .dialog-header {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 16px;

      .close {
        text-align: center;
        border-radius: 100px;
        background-color: white;
        width: 40px;
        height: 40px;
        font-size: 24px;
        color: $midnight70;
        line-height: 36px;
        cursor: pointer;
        box-shadow: 1px 1px 20px rgba(112, 117, 121, 0.2);
      }
    }

    .dialog-content {
      position: relative;
      padding: 16px;
      background-color: white;
      border-radius: 16px;
      box-shadow: 1px 1px 20px rgba(112, 117, 121, 0.2);

      @media (max-width: $mobile-max-width) {
        overflow-y: scroll;
      }
    }
  }
}
