@import "../../../../styles/variables";

.StateRenderer {
  margin: 0;
  margin-top: -15px;
  padding: 8px 14px;
  height: 25px;
  width: 48px;
  border-radius: 100%;
  font-weight: bold;
  text-align: center;
  position: relative;
  font-size: x-small;
  top: 8px;

  :global span {
    top: 6px;
    position: relative;
  }
}

.ActiveStateRenderer {
  @extend .StateRenderer;
  background-color: $background-green;
  color: $green;
}
.InactiveStateRenderer {
  @extend .StateRenderer;
  background-color: $background-red;
  color: $red;
}
