@import "styles/variables";
@import "styles/buttons";
@import "styles/ag-grid";

body {
  background-color: $background-blue;

  &.no-scroll {
    overflow: hidden;
  }

  a {
    color: $gulf;
    text-decoration: none;
    &:visited {
      color: $gulf;
      text-decoration: none;
    }
  }

  input,
  select {
    margin: 11px 0;
    padding: 16px;
    border: 1px solid $midnight10;
  }

  iframe {
    border: none;
  }

  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
