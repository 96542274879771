@import "../../../../styles/variables";

.component-subscriber {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  overflow: hidden;

  &:not(:last-child) {
    border-bottom: 1px solid $midnight10;
  }
  .col {
    width: 16.666%;
    padding: 16px 0;
    overflow-wrap: break-word;

    color: $midnight70;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    &:last-child {
      text-align: right;
      width: 50px;
    }
  }

  .name {
    color: $midnight100;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 6px;
  }

  .methods {
    color: $dusk40;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  .icon-button {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
  .icon {
    &.edit {
      margin-right: 13px;
    }
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: $gulf;
      }
    }
  }

  .subscribe-channel {
    display: flex;
    flex-direction: column;

    .subscription-badge {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 10px;
      margin: 0 6px 3px 0;
      gap: 8px;
      border-radius: 100px;
      width: max-content;

      font-weight: 800;
      font-size: 10px;
      line-height: 11px;
      color: $dusk;

      &.SensorNoiseAlert,
      &.SensorTemperatureAlert {
        background-color: $laguna30;
      }
      &.RouterConnectedAlert {
        background-color: $gulf10;
      }

      .icon {
        svg {
          position: relative;
          width: 18px;
          height: 18px;
          top: 1px;

          path {
            fill: $dusk;
          }
        }
      }
    }
  }

  @media (max-width: $mobile-max-width) {
    flex-direction: column;

    .col {
      width: 100%;
      padding: 6px 0;

      &:last-child {
        width: 100%;
      }

      &.subscriber-id,
      &.subscriber-email,
      &.subscriber-phone {
        padding: 2px 0;
      }
      &.subscriber-phone {
        padding-bottom: 6px;
      }
    }
  }
}
