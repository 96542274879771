@import "../../styles/variables";

.component-sound-monitoring {
  .card {
    width: 100%;
    height: min-content;
    background-color: white;
    margin: 16px 0;
    padding: 8px 0;
    box-shadow: 1px 1px 20px rgba(112, 117, 121, 0.2);
    border-radius: 4px;

    .card-container {
      margin: 0 24px;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 12px;

        margin: 8px 0;

        .icon {
          position: relative;
          top: 2px;
          width: 24px;
          height: 24px;
        }

        .title {
          color: $dusk;
          font-weight: 800;
          font-size: 21px;
          line-height: 28px;
          white-space: break-spaces;
        }

        .refresh-btn {
          position: relative;
          display: inline-block;
          color: $gulf;
          cursor: pointer;

          .icon-refresh {
            position: relative;
            display: inline-block;
            width: 18px;
            height: 18px;
            top: 5px;
            path {
              fill: $gulf;
            }
          }

          button {
            margin: 0;
            padding: 0;
            vertical-align: middle;
            background-color: transparent;
          }
        }
      }
    }
  }

  .columns {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 32px;

    @media (max-width: $mobile-max-width) {
      display: block;
    }

    .column {
      width: 100%;
      display: flex;
      padding: 0;
    }
  }
  .timezoneDisplay {
    font-size: x-small;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
