@import "../../../../styles/variables";

.component-device-item {
  position: relative;
  padding: 16px 0;
  &:not(:last-child) {
    border-bottom: 1px solid $midnight10;
  }

  .component-wrapper {
    position: relative;

    .badge {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 10px;
      gap: 8px;
      border-radius: 100px;
      width: max-content;

      font-weight: 800;
      font-size: 10px;
      line-height: 12px;
      color: $dusk;

      &.Sensor {
        background-color: $laguna30;
      }
      &.Router {
        background-color: $gulf10;
      }

      .icon {
        svg {
          position: relative;
          width: 21px;
          height: 21px;
          top: 1px;

          path {
            fill: $dusk;
          }
        }
      }
    }

    .macAddress {
      float: right;
      color: $midnight70;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .actions {
      float: right;
      .icon-button {
        color: $gulf;
        margin: 0 7px;
        padding: 0;
        vertical-align: middle;
        background-color: transparent;
        font-size: 18px;

        .icon {
          width: 18px;
          height: 18px;
          stroke: transparent;
          path {
            fill: $gulf;
          }
        }
      }
    }

    .device-summary {
      margin: 8px 0;
      position: relative;
      color: $midnight100;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;

      .wrap-text {
        position: relative;
        display: inline-block;
        max-width: 70%;
        word-wrap: break-word;
      }
    }

    .device-data-meta {
      color: $dusk40;
      font-weight: 500;
      font-size: 12px;
      line-height: 33px;
      display: flex;
      justify-content: space-between;
    }

    .device-data-meta-text {
      display: flex;
    }

    .alert-status-badge {
      display: flex;
      background: $midnight4;
      border-radius: 8px;
      padding: 8px;

      color: $midnight70;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;

      &.disabled {
        color: $red;
        background: $background-red;
      }
    }

    .device-without-detail {
      color: $dusk40;
      font-weight: 500;
      font-size: 12px;
    }
  }

  @media (max-width: $mobile-max-width) {
    .component-wrapper {
      .macAddress {
        display: block;
        float: none;
        margin: 6px 0;
      }
      .actions {
        display: block;
        float: none;
        padding: 0;
        margin: 12px 0 0 0;
      }
      .device-data-meta {
        line-height: 16px;
      }
      .alert-status-badge {
        display: block;
        position: relative;
        float: none;
        width: min-content;
        white-space: nowrap;
        margin-top: 12px;
      }
    }
  }
}
