@import "../../../../styles/variables";

.DeviceStatusRenderer {
  position: relative;
  display: inline-block;

  &:hover {
    :global .Tooltip {
      visibility: visible;
    }
  }

  :global .Tooltip {
    position: absolute;
    visibility: hidden;
    background-color: $midnight80;
    color: $midnight4;
    padding: 0 8px;
    z-index: 1;
    right: 22px;
  }
}
